<template>
  <div class="absolute">
    <ul class="flex flex-col items-start justify-center p-4">
      <li>Menú</li>
      <li><router-link :to="{name: 'Contact'}" >Contáctanos</router-link></li>
      <li><router-link :to="{name: 'About'}">Quienes Somos</router-link></li>
      <li><button class="uppercase" type="button" @click="loginForm">iniciar sesión</button></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "NavigationMobile",
  methods: {
    loginForm() {
      this.$emit('showLogin', true)
    }
  }
};
</script>
<style scoped>
ul li {
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: white;
      padding: 2px 10px;
      margin: 5px;
}
ul li:hover {
  color: var(--orange_1) !important;
  font-weight: 700;
}
</style>
