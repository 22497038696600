import { createApp } from 'vue';
import App from './App.vue';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import router from './router';
import './styles/app.css';
import "@fortawesome/fontawesome-free/css/all.css";

import { createPinia } from 'pinia';

const pinia = createPinia() 
const app = createApp(App) 

app
  .use(router)
  .use(VueSweetalert2)
  .use(pinia)
  .mount('#app');


