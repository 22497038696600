<template>
    <div class="login-form md:w-3/12 w-11/12 p-6 border">
        <button type="button" class="absolute top-8px right-8%" @click="closeLogin">
            <i class="fas fa-times"></i>
        </button>
        <form class="flex flex-col gap-y-4" >
            <div class="flex flex-col items-start gap-y-1">
                <label class="pl-2" for="user">Email</label>
                <input type="text" id="user" name="user" v-model="user"
                class="bg-transparent border-2 w-full text-xl px-2">  
            </div>
            <div class="relative flex flex-col items-start gap-y-1">
                <label class="pl-2" for="pass">Contreña</label>
                <input id="pass" name="pass" v-model="pass"
                :type="(showpass) ? 'text' : 'password'" 
                class="bg-transparent border-2 w-full text-xl px-2">           
                <button class="absolute top-2rem right-1rem" type="button" @click="showpass=!showpass" v-show="!showpass"><i class="fas fa-eye-slash"></i></button>
                <button class="absolute top-2rem right-1rem" type="button" @click="showpass=!showpass" v-show="showpass"><i class="fas fa-eye"></i></button>       
            </div>
            <div class="flex flex-col items-center gap-y-3">
                <small v-show="is_error" class="bg-red text-white p-1">
                    <i class="fa fa-exclamation-triangle p-1"></i>
                    {{error_message}}
                </small>
                <router-link class="text-light_blue_1 underline" :to="{name: 'ForgotPassword'}" @click="closeLogin">Olvide mi contraseña</router-link>
                <button type="submit"                
                class="border border-orange_1 bg-orange_1 py-2 px-16" @click="login">Iniciar sesión</button>
                <button type="button" @click="newSubcribe"
                class="border border-orange_1 bg-transparent text-orange_1 py-2 px-16">Registrarse</button>
            </div>
        </form>        
    </div>
</template>
<script>
import Authorization from "@/services/user/auth.service";
import ClientService from "@/services/user/client.service";

export default {
    props: {
        isMobile: {
            default: false
        },
        logout: {default:false}
    },
    name:'LoginForm',
    data() {
        return {            
            user: '',
            pass: '',
            showpass: false,
            disabledLogin: false,
            is_error: false,
            error_message: 'Error de credenciales.'
        }
    },    
    watch: {
        logout() {
            this.disabledLogin = false
        }
    },
    computed: {
        isLoggued() {            
            if(Authorization.isClientLogged()) {
                this.$emit('logged', true)
                return true
            }
            return false
        },
    },
    methods: {
        closeLogin() {
            this.$emit('closeLogin', false)            
        },
        async login(e) {
            e.preventDefault();  
            if(!this.isLoggued)  {       
                ClientService.login(this.user, this.pass).then(res => {                               
                    if(res.logged) {
                        this.closeLogin()
                        localStorage.setItem('user_msg', res.message)
                        this.$emit('logged', true)                        
                    } else {
                        this.error_message = res.message
                        this.is_error = true
                        setTimeout(() => {
                            this.is_error = false                    
                        }, 8000)
                    }
                }).catch(err => {
                    console.log(err.message)
                    this.$swal({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',                
                        confirmButtonColor: '#3085d6',                            
                    }) 
                }) 
            }
        },
        newSubcribe() {
            if (this.$route.fullPath != '/NewClient')
                this.$router.push({name: 'NewClient'})

            this.$emit('closeLogin', false)
        }
    }
}
</script>
<style scoped>
.login-form {
    color: white;
    background: rgba(29,26,57,0.9);
}
    
</style>
