<template class="flex justify-center">
  <nav class="bg-white pt-20 pb-5" aria-label="Breadcrumb" v-if="breadcrumbs.length > 0">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      
      <li class="flex items-center">
          <router-link :to="'/'" class="font-DIN-Light inline-flex items-center text-sm text-blue_1 hover:font-DIN-Bold">
            <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
            Home
          </router-link>
      </li>
      
      <li v-for="(b, index) of breadcrumbs" :key="index">
        <div class="flex items-center">
          <span v-html="join"></span>          
          <router-link class="font-DIN-Light ml-1 text-sm text-blue_1 hover:font-DIN-Bold md:ml-2"
          v-if="(index+1) < breadcrumbs.length"
          :to="b.link">{{ b.text }}</router-link>
          <span class="ml-1 text-sm text-blue_1 font-DIN-Bold md:ml-2"
          v-if="index+1 == breadcrumbs.length">{{ b.text }}</span>
        </div>
      </li>      
      <li v-show="results"></li>
    </ol>
  </nav>
</template>

<script>
// import route from './router.vue';

  export default {
    name: 'BreadCrumb',    
    data() {
      return {
        join: '<svg aria-hidden="true" class="w-6 h-6 text-light_gray_1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>',
        breadcrumb: []  
      };
    },
    created() {      
      
    },
    computed: {
      breadcrumbs() {
        let breadcrumb = []      
        const hasParams = Object.keys(this.$route.params).length > 0
        
        if(!hasParams && 'breadcrumb' in this.$route.meta) {
          breadcrumb.push(this.$route.meta.breadcrumb)
        } else if(hasParams && 'breadcrumb' in this.$route.meta) {
          breadcrumb = this.$route.meta.breadcrumb(this.$route.params) 
        } 
        return breadcrumb
      }
   },
  };
</script>

  <style scoped>
  </style>