import axios from 'axios';
// import httpClient from "./http.service";
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';
import API from '@/config';

/** Default config for axios instance */
// const API_ENDPOINT = API.api
const API_ENDPOINT = API.api_dotcom 

let config = {
    baseURL: `${API_ENDPOINT}`,
    auth: {
        username: 'api@admin.com',
        password: 'IT_api'
    }
};
/** Creating the instance for axios */
const httpClient = axios.create(config);

const authService = {
    currentUserData: [],
    // login to generic api-user 
    async login() {
        if (!sessionStorage.getItem('token')) {
            try {
                const { status, data } = await httpClient.get(`/user-api/login`)
                if (status === 200) {
                    sessionStorage.setItem('token', data.token)
                    return data.token
                }
            } catch (e) {
                return {
                    success: false,
                    errors: e//.response.data.errors.password[0]
                }
            }
        }
    },
    async logout() {        
        try {
            // Llamando al API para desloguearse //     
            const res = await this.checkLogin(`/clients/logout`, {auth: 'bearer'})
            if(res.status === 200) {
                sessionStorage.setItem('token', res.data.token)              
                authService.destroyLocalUserData()
            }
        } catch(e) {                
            console.log('problemas al desloguearse')
            console.log(e)
        }        
    },
    isClientLogged() {
        if (sessionStorage.getItem('token')) {
            const token = jwt_decode(sessionStorage.getItem('token'))
            return (token.client) ? true : false
        }
        return false
    },
    async checkLogin(url, paramsData = {}, method = 'get') {
        // Check if we are logged in the API
        const request_config = {
            url,
            method
        },
        token = sessionStorage.getItem('token')

        if (paramsData.data) request_config.data = paramsData.data
        if (paramsData.params) request_config.params = paramsData.params
        let headers = paramsData.headers ? paramsData.headers : {};

        if (token) {
            if (paramsData.auth === 'bearer') {           
                let req = null;
                if (method === 'get')
                req = await this.getAxiosToken(token, config.baseURL + url,headers)
                else
                    req = this.otherAxiosToken(token, config.baseURL + url, paramsData, method)
                try {                    
                    return await req
                } catch (err) {
                    try{
                        // Comprueba si el token está vencido
                        console.log(err)
                        if (err.response && err.response.status === 401) {
                            // Refresca el token
                            await this.refreshToken();
                            // Reintenta la solicitud
                            await this.checkLogin(url, paramsData, method)
                        } 
                        throw new Error(err.message);
                    } catch (err) {
                        throw new Error(err.message);
                    }
                }
            }
            return axios.get(config.baseURL + url, { 
                auth: {
                    username: 'api@admin.com',
                    password: 'IT_api'
                }
            }).then(resp => {
                return resp
            }).catch(err => {
                return err
            })
        }
        throw new Error('Something bad happened.');

    },
    async checkLoginImage(url, paramsData = {}, method) {
        // Check if we are logged in the API        
        const token = sessionStorage.getItem('token')
        // console.log(paramsData)
        if (token) {            
            let req = null;
            req = this.otherAxiosToken(token, config.baseURL + url, paramsData, method)
            return req
        }
        this.login()
        this.checkLogin()

    },
    async otherAxiosToken(token, url, params, method) {
        try {
            const result = (method == 'post') ?
                await axios.post(url, params, { headers: { Authorization: 'Bearer ' + token } }) :
                await axios.put(url, params, { headers: { Authorization: 'Bearer ' + token } })
            return result
        } catch (e) {            
            throw new Error(e.message);
        }
    },  
    async getAxiosToken(token, url,headers = {}) {        

        if(!headers)
            return axios.get(url, { headers: { Authorization: 'Bearer ' + token } }).then(resp => {
                return resp
            }).catch(err => {
                return err
            })

        // Hay headers
        let newHeaders = { ...headers, Authorization: 'Bearer ' + token };        
        return axios.get(url, {headers: newHeaders}).then(resp => {
            return resp
        }).catch(err => {
            return err
        })
    },
       
    getClientToken() {
        return this.decodeToken(sessionStorage.getItem('token'))
    },
    decodeToken(token) {
        return jwt_decode(token)
    },
    createLocalUserData() {
        const client = this.getClientToken().client
        localStorage.setItem('id', client.id)
        localStorage.setItem('user', client.name)        
        localStorage.setItem('roles', client.roles)
        localStorage.setItem('email', client.email)
        if('margin' in client){
            localStorage.setItem('margin', JSON.stringify(client.margin))
        }
    },
    destroyLocalUserData() {
        localStorage.clear()
    },
    isLogged() {
        const token = this.getClientToken()
        return ('client' in token)
    },
    validateRole(roles, exclusive=false) {
        if (this.isClientLogged()) {
            const current_roles = localStorage.getItem('roles').split(',')
            
            if (!exclusive) {
                if(current_roles.includes('ROLE_SuperAdmin')){
                    return true
                }
           }         
           if(roles != undefined) {
               for(let r of roles){                
                    if(current_roles.includes(r)){
                        return true
                    }
                }
            }
        } else {
            // Client not logued
            this.destroyLocalUserData()
            return false
        }
        return false
    },
    encryptPass(pass) {
        // const decodeToken = this.getClientToken()
        const token = sessionStorage.getItem('token').slice(-10)
        let encrypted_pass = null        
        let salt = CryptoJS.lib.WordArray.random(128 / 8);        
        if (token) {
            encrypted_pass = CryptoJS.AES.encrypt(pass, token, salt).toString();
        }
        console.log(encrypted_pass)
        return encrypted_pass
    },
    async refreshToken() {
        return axios.get(config.baseURL + '/refresh', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
        }).then(resp => {
            sessionStorage.setItem('token', resp.data.token);
            return resp;
        }).catch(err => {
            console.error(err);
            throw err;
        });
    },
       
}

export default authService