import Authorization from "./auth.service";

const ClientService = {
    async login(user, pass) {
        try {
            // Llamando al API para loguearse //                 
            pass = Authorization.encryptPass(pass)            
            const resp = await Authorization.checkLogin(`/clients/login`, 
            { 
                auth: 'bearer', 
                headers: {
                    email: user,
                    password: pass,
                } 
            })    

            if (resp.status == 200) {
                    sessionStorage.setItem('token', resp.data.token)
                    // Creando variables con datos del usuario
                    Authorization.createLocalUserData()
                    return {
                        message: 'Success!!!',
                        error: false,
                        logged: true
                    }
            } else if (resp.status == 400) {
                return {
                    message: resp.data.message,
                    error: true,
                    logged: false
                }
            } else {  
                console.log(resp.response.data)                     
                throw resp.response.data
            }
        } catch (e) {
            console.log(e)
            throw new Error(e);
        }
    },
    getList() {
        try {
            return Authorization.checkLogin(`/clients`).then(res => {
                let list = []
                if (res.status == 200) {       
                    if(Authorization.validateRole(['ROLE_Admin'])) {
                        for (let item of res.data) {
                            list.push({
                                id: item.id,
                                name: item.name,
                                email: item.email,
                                active: (item.active) ? true : false,
                                verify: (item.verify) ? true : false,
                                agent: (item.agent) ? true : false
                            })
                        }
                    } else if (Authorization.validateRole(['ROLE_Comercial'])) {      
                        for (let item of res.data) {
                            if(item.email == localStorage.getItem('email'))
                                list.push({
                                    id: item.id,
                                    name: item.name,
                                    email: item.email,
                                    active: (item.active) ? true : false,
                                    verify: (item.verify) ? true : false,
                                    agent: (item.agent) ? true : false
                                })
                        }                  
                    } else if (Authorization.validateRole(['ROLE_Agency'])) {                        
                        list = this.validateRoleUser(res.data)
                    }
                }
                return list
            })
        } catch (e) {
            console.log(e)
        }
    },
    async addUpdClient(client_data, is_new = true) {
        try {
            //encrypting pass
            client_data.password = Authorization.encryptPass(client_data.password)
            
            client_data.auth = 'bearer'
            const { status, data } = (is_new) ?
            await Authorization.checkLogin('/clients/create', client_data, 'post'):
                await Authorization.checkLogin('/clients/upd', client_data, 'put')
            if (status == 200) {
                return data
            }
        } catch (e) {
            console.log(e.response)
        }
        return false
    },
    async addUpdVendor(client_data) {
        try {
            //encrypting pass
            client_data.password = Authorization.encryptPass(client_data.password)
            
            client_data.auth = 'bearer'
            const { status, data } =  await Authorization.checkLogin('/clients/add-upd/vendor', client_data, 'post')
                
            if (status == 200) {
                return data
            }
        } catch (e) {
            console.log(e.response)
        }
        return false
    },
    async getClient(id) {
        try {
            const { status, data } = await Authorization.checkLogin(`/clients/${id}`, { auth: 'bearer' })
            if (status == 200) {                
                return data
            }
        } catch (e) {
            console.log(e.response)
        }
    },
    async getVendors() {
        try {
            const { status, data } = await Authorization.checkLogin(`/clients/vendors`, { auth: 'bearer' })
            if (status == 200) {
                return data
            }
        } catch (e) {
            console.log(e.response)
        }
    },
    async delete(ids) {
        const delete_data = {
            auth: 'bearer',
            ids: (typeof ids == Array) ? ids : [ids]
        }
        try {
            const { status } = await Authorization.checkLogin('/clients/delete', delete_data, 'post')
            if (status == 200) {
                if(Authorization.validateRole('ROLE_Agency')) 
                    return await this.getVendors()
                else 
                    return await this.getList()
            }
        } catch (e) {
            //! Fail 
            console.log(e.response)
        }
    },
    validateRoleUser(client_list) {
        const current_email = localStorage.getItem('email'),
         parent = client_list.filter(e => e.email == current_email),
         show_list = []
        
        show_list.push({
            id: parent[0].id,
            name: parent[0].name,
            email: parent[0].email,
            active: (parent[0].active) ? true : false,
            verify: (parent[0].verify) ? true : false,
            agent: (parent[0].agent) ? true : false,
            vendor: false
        })
        for (let item of client_list) {
            if(parent[0].id == item.parent){
                show_list.push({
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    active: (item.active) ? true : false,
                    verify: (item.verify) ? true : false,
                    agent: (item.agent) ? true : false,
                    vendor: true
                })
            }
        }
        return show_list
    },
    async verifyUser(token) {
        try {
            const { status, data } = await Authorization.checkLogin(`/verify-user`,{token, auth: 'bearer'}, 'post' )
            if (status == 200) {
                return data
            }
        } catch (e) {
            console.log(e.response)
        }
    }
}

export default ClientService