<template>
  <footer class="z-0 flex flex-col px-4 pt-10 pb-4 mt-auto border-t border-white">          
    <div class="flex flex-col gap-6 text-white md:flex-row">
      <figure class="flex flex-col items-center order-1 gap-5 py-3 md:w-3/12 md:order-1 justify-evenly sm:py-0">
          <img
          :src="logo_img"
          class="w-2/6 m-auto sm:m-0"
          alt="logo"
          />
        <ul class="flex flex-row justify-center gap-6 p-2 text-3xl social-media sm:justify-end">
          <li class="flex items-center rounded-full bg-white text-blue_1 px-[6px]"><a href="https://instagram.com/iris_travel_group?igshid=YmMyMTA2M2Y="><i class="fab fa-instagram"></i></a></li>
          <li class="flex items-center rounded-full bg-white text-blue_1 px-[10px]"><a href="https://www.facebook.com/iristravelgroupUSA?mibextid=LQQJ4d"><i class="fab fa-facebook-f"></i></a></li>
          <li class="flex items-center rounded-full bg-white text-blue_1 px-[6px]"><a href="https://wa.me/17868006595"><i class="fab fa-whatsapp"></i></a></li>
        </ul>
      </figure>
      <div class="flex flex-col order-3 gap-4 md:w-2/12 md:order-1 md:text-left md:border-l-2 md:px-6">
        <h3 class="hidden text-2xl uppercase font-DIN-Bold md:block">soporte</h3>
        <ul class="text-base lg:text-xl">
          <li><router-link :to="{name: 'About'}">Quienes Somos</router-link></li>
          <li><router-link :to="{name: 'TermsAndPolicy'}" target='_blank'>Términos y políticas</router-link></li>
          <li><router-link :to="{name: 'Contact'}" >Contáctanos</router-link></li>
          <li><a href="http://blog.iristravelgroup.com/" target="_blank">Iristravel Group Blog</a></li>          
        </ul>
      </div>
      <div class="flex flex-col order-4 gap-4 md:order-1 md:text-left md:border-l-2 md:px-6">
        <h3 class="hidden text-2xl uppercase font-DIN-Bold md:block">Ubicacion</h3>
        <div class="text-base lg:text-xl">
          <a href="https://goo.gl/maps/P76yQdQ7y7BYijwv9">
            <span class="break-words">{{ dir }}</span>
          </a>
        </div>
      </div>
      <div class="flex flex-col order-2 gap-4 md:w-2/12 md:order-1 md:text-left md:border-l-2 md:px-6">
        <h3 class="hidden text-2xl uppercase font-DIN-Bold md:block">contacto</h3>
        <div class="text-base md:text-xl"><pre>{{ phones }}</pre></div>
      </div>
    </div>
    <div class="py-6 mx-auto text-white">
      <small>{{ copyright }}</small>
    </div>
  </footer>
</template>

<script>
import config from "@/config";
import textServices from "@/services/common/texts.service";

export default {
  name: "FooterComp",
  data() {
    return {
      logo_img: config.gallery_url + "general/logo/logo-white.png",
      dir: "",
      phones: "",
      copyright: ""
    };
  },
  created() {
    textServices.getTexts({data: ["direccion", "contact-phone", "copyright"]}).then(t => {      
      if(t) {
        this.dir        = t.filter(item => item.keyword === 'direccion')[0].description
        this.phones     = t.filter(item => item.keyword === 'contact-phone')[0].description
        this.copyright  = t.filter(item => item.keyword === 'copyright')[0].description
      }
    });

  }
};
</script>
<style scoped>
footer {
  background-color: var(--blue_1);
  grid-template-columns: 15% auto 15%;
  grid-template-rows: auto auto auto;
}
footer .social-media p {
  font-size: 14px;
}

@media screen and (min-width: 640px) {
  footer {
    grid-template-columns: 15% 20% 15% 35% 15%;
  }
  footer .social-media p {
    text-align: right;
  }
}
</style>
