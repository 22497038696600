import Authorization from "../user/auth.service";

const TextService = {          
    async getText(key) { //! Obtiene los datos x un ID
        try {
            const text = await Authorization.checkLogin(`/text/by-key/${key}`)
            if (text) {
                return (text.status == '200') ? text.data : []
            } 
            // const { status, data } = await Authorization.checkLogin(`/text/by-key/${key}`)
            // if (status == 200) {
            //     return data
            // }
        } catch (e) {
            console.log(e)
        }
    },
    async getTexts(keys) { //! Obtiene los datos x un ID
        try {
            keys.auth = 'bearer'
            const text = await Authorization.checkLogin(`/text/by-keys`, keys, 'post')
            if (text) {
                return (text.status == '200') ? text.data : []
            }            
        } catch (e) {
            console.log(e)
        }
    }   
}

export default TextService