import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home.vue"),    
  },
  {
    path: "/acerca-de",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      breadcrumb: {
        text: 'Acerca de',
        link: '/acerca-de'
      }
    }
  },
  // POLES ROUTES
  {
    path: "/destinos/:keyword",
    name: "SinglePole",
    component: () => import(/* webpackChunkName: "pole" */ "@/views/Pole.vue"),
    meta: {
      breadcrumb ({keyword}) {
          return [{
            text: `Destino ${keyword}`,
            link: `/destinos/${keyword}`
          }]     
      },
    }
  },  
  // Products List Wrapper
  {
    path: "/destinos/:poleKey/:productKey/",
    name: "ProductsListWrapper",
    component: () => import(/* webpackChunkName: "products_list" */ "@/views/Products/ProductsListWrapper.vue"),
    meta: {
        breadcrumb ({poleKey, productKey}) {
            return [
                {
                    text: `Destino ${poleKey}`,
                    link: `/destinos/${poleKey}`
                },
                {
                    text: `Producto ${productKey}`,
                    link: `/destinos/${poleKey}/${productKey}`
                },              
            ]     
        },
    }
  },
  // Product Detail Wrapper
  {
    path: "/destinos/:poleKey/:productKey/:keyword",
    name: "ProductDetail",
    component: () => import(/* webpackChunkName: "product_detail" */"@/views/Products/ProductDetailWrapper.vue"),
    meta: {
        breadcrumb ({poleKey, productKey, keyword}) {
            return [
                {
                    text: `Destino ${poleKey}`,
                    link: `/destinos/${poleKey}`
                },
                {
                    text: `Producto ${productKey}`,
                    link: `/destinos/${poleKey}/${productKey}`
                },
                {
                    text: `${keyword}`,
                    link: `/destinos/${poleKey}/${productKey}/${keyword}`
                },
            ]     
        },
    }
  },

  // SEARCH-RESULT
  {
    path: "/resultado-de-busqueda",
    name: "SearchResult",
    component: () => import(/* webpackChunkName: "search_result" */ "@/views/SearchResult.vue"),
    meta: {
      breadcrumb: {
        text: 'Resultados',
        link: '/resultado-de-busqueda'
      }
    }
  },

  // TERM & POLICY
  {
    path: "/terminos-politicas",
    name: "TermsAndPolicy",
    component: () => import(/* webpackChunkName: "terminos_politicas" */ '@/views/Policy.vue'),
    meta: {
      breadcrumb: {
        text: 'Política de privacidad',
        link: '/terminos-politicas'
      }
    }
  },

  // CONTACT US
  {
    path: "/contactenos",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ "@/views/Contacto.vue"),
    meta: {
      breadcrumb: {
        text: 'Contactenos',
        link: '/contactenos'
      }
    }
  },

  // SEND REQUEST
  {
    path: "/envio-de-solicitud",
    name: "SendRequest",
    component: () => import(/* webpackChunkName: "send_request" */ "@/views/Request.vue"),
    meta: {
      breadcrumb: {
        text: 'Solicitud',
        link: '/envio-de-solicitud'
      }
    }
  },

  // CLIENT SUBSCRIPTION
  {
    path: "/usuario/suscripcion",
    name: "NewClient",    
    component: () => import("@/views/Clients/NewClient.vue"),   
    meta: {
      breadcrumb: {
        text: 'Suscripcion',
        link: '/usuario/suscripcion'
      }
    }   
  },
  {
    path: "/usuario/olvide-mi-clave",
    name: "ForgotPassword",
    component: () => import("@/views/Clients/ForgotPassword.vue"),
    meta: {
      breadcrumb: {
        text: 'Clave olvidada',
        link: '/suario/olvide-mi-clave'
      }
    }
  },  
  {
    path: "/usuario/olvide-mi-clave/:token",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "forgot_password" */ "@/views/Clients/ForgotPassword.vue"),
    // meta: {
    //   breadcrumb: {
    //     text: 'Solicitud',
    //     link: '/usuario/olvide-mi-clave/:token'
    //   }
    // }
  }, 
  {
    path: "/verificacion-de-cuenta/:token",
    name: "VerifyUser",
    component: () => import("@/views/Clients/VerifyUser.vue"),
    // meta: {
    //   breadcrumb: {
    //     text: 'Solicitud',
    //     link: '/envio-de-solicitud'
    //   }
    // }
  },
  
  // Admin
  {
    path: "/admin",
    name: "Admin",        
    component: () => 
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Admin.vue")
  },
  {
    path: "/admin/:CrudKey",
    name: "CrudKey",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin/Admin.vue"),    
  },  
  
  // 404
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not_found" */ "@/views/404.vue"),    
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {  
  if(to.matched.length === 0)
    next({name: 'NotFound'})
  else
    next()
})

export default router
