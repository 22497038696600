<template>
    <div id="app" class="relative" :class="{'bg-blue_1': showMobileMenu}">
      <navigation-mobile v-show="showMobileMenu" @showLogin="showLogin"></navigation-mobile>
      <main ref="header"
        class="flex flex-col h-full min-h-screen rounded-t-2xl sm:rounded-t-0"
        :class="{ 'menu-mobile-open': showMobileMenu }"
      >
      <template v-if="api_connected">
        <Header @handleMobileMenu="showMobile" @showLogin="showLogin" :showMobile="showMobileMenu" :login="openLogin"></Header>
        <router-view />
        <Footer></Footer>        
      </template>      
        <span class="cursor-pointer fixed bottom-[5%] right-[5%] bg-orange_1 text-bold text-xl text-white p-4 px-6 opacity-60 rounded-full
        hover:opacity-100"
        @click="$refs.header.scrollIntoView({behavior: 'smooth'})">
          <i class="fas fa-arrow-up"></i>
        </span>
      </main>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import NavigationMobile from "@/components/NavigationMobile.vue"
import Authorization from "@/services/user/auth.service"

export default {
  components: {
    Header,
    Footer,
    NavigationMobile,    
  },  
  data() {
    return {
      openLogin: false,
      showMobileMenu: false,
      api_connected: false
    };
  },  
  created() {
    Authorization.login().then(() => {
      this.api_connected = true
    })
  },  
  methods: {    
    showMobile(show) {
      this.showMobileMenu = show;
    },
    showLogin(status) {      
      this.showMobileMenu = false
      this.openLogin = status;
    },
  },
};
</script>

<style>
:root {
  /* --orange_1: #E4A91D; */
  --red: #bb2a1f;
  --blueOld: #232E6E;       /*  old  */
  --blueIritravel: #21285E; /*  old  */
  --variable: #4640F8;      /*  old  */
  --orange_1: #E3A81D;      /*  orange_1   */
  --blue_1: #243886;        /*  blue_1 */
  --light_blue_1: #a4c2fc;  /*  light_blue_1 */
  --dark_blue_1: #1D1A39;   /*  dark_blue_1  */
  --light_gray_1: #c4c4c4;
  --dark_gray_1: #4b4b4b;
  --back_board: #e2e6ee;    /*  back_board */
  --hovershadow: 0px 0px inherit inherit;
}

@font-face {
    font-family: 'Clarissa';
    src: url(assets/fonts/Clarissa.ttf) format("truetype");
 }
 
 /*@font-face {
    font-family: 'DINPro';
    src: url(assets/fonts/DINPro.ttf) format("truetype");
 }*/
 @font-face {
  font-family: 'DINPro-Regular';
  src: url(assets/fonts/DINPro-Regular.ttf) format("truetype");
}
 @font-face {
  font-family: 'DINPro-Light';
  src: url(assets/fonts/DINPro-Light.ttf) format("truetype");
} 
 @font-face {
    font-family: 'DINPro-Bold';
    src: url(assets/fonts/DINPro-Bold.ttf) format("truetype");
 }
 @font-face {
    font-family: 'DINPro-Medium';
    src: url(assets/fonts/DINPro-Medium.ttf) format("truetype");
 }
 @font-face {
    font-family: 'DINPro-Black';
    src: url(assets/fonts/DINPro-Black.ttf) format("truetype");
 }
 
 @font-face {
   font-family: 'Servicios';
   src: url(assets/fonts/Servicios.ttf) format("truetype");
 }

#app {
  font-family: DINPro, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*background-color: var(--back_board);*/
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: var(--light_blue_1);
}
.menu-mobile-open {
    transform: translateX(250px);
    transition: 1s transform cubic-bezier(.02,.13,0,.99);
}

</style>
