<template>
  <header ref="header" class="it-header-menu relative bg-white">
    <div class="flex justify-end gap-x-6 m-auto w-90x100 py-3" v-if="logged">
        <span>
          <i class="fa fa-user"></i>
          {{ user_name }}
        </span>
        <router-link v-if="logged" :to="{name: 'Admin'}"><i class="fas fa-cogs text-xl"></i></router-link>
        <a href="javascript:void(0)" type="button" v-if="logged" @click="logout"><i class="fas fa-sign-out-alt text-xl"></i></a>
    </div>
    <nav v-if="!showNavMobile" class="grid grid-cols-3 py-4">      
      <ul class="flex justify-center items-center">
        <li><router-link :to="'/'">Home</router-link></li>
        <li><router-link :to="{name: 'Contact'}">Contáctanos</router-link></li>
      </ul>
      <figure>
        <router-link :to="'/'">          
          <img src="@/assets/img/logo-blue.png" class="w-2/6 m-auto" alt="logo" />
        </router-link>              
      </figure>
      <ul class="flex justify-center items-center">
        <li><router-link :to="'/acerca-de'">quienes somos</router-link></li>
        <li v-if="!logged">
          <button type="button" class="uppercase font-bold" @click="loginForm">iniciar sesión</button>                    
        </li>
        <li v-if="logged">
          <button type="button" class="uppercase font-bold" @click="logout">cerrar sesión</button>                    
        </li>
      </ul>
    </nav> 

    
    <login-form v-show="login" :isMobile="isMobile" :logout="logged" @closeLogin="loginForm(false)" @logged="updClientStatus"
    class="absolute top-1/2 z-10 right-2%"></login-form>     
    <!-- Mobile navbar -->
    <div v-if="showNavMobile"
      class="relative flex flex-row justify-around p-3 text-4xl"
    >
      <button @click="showMobileMenu">
        <span v-show="!isMobile"><i class="fas fa-bars"></i></span>
        <span v-show="isMobile"><i class="fas fa-times"></i></span>
      </button>
      <figure class="self-center">
        <router-link :to="'/'">          
          <img src="@/assets/img/logo-blue.png" class="w-2/5 ml-auto" alt="logo" />
        </router-link>
      </figure>
    </div> 
    
    <BreadCrumb />  

  </header>
</template>
<script>
import LoginForm from '@/components/LoginForm.vue'
import Authorization from "@/services/user/auth.service";
import BreadCrumb from '@/components/Breadcrumb/BreadCrumb.vue'

export default {  
  name: "HeaderComp",  
  props: {
    login: {
      default: false
    },
    showMobile: {
      default: false
    }
  },
  components: {
    LoginForm,
    BreadCrumb
  },
  data() {
    return {
      openMenu: false,
      showNavMobile: false,
      isLogged: false,
      user_name: ''
    };
  },
  created() {
    this.handleMobileView();    
  },
  watch: {
    showMobile(value) {
      this.openMenu = value
    }
  },
  computed: {
    logged() {
      return this.isClientLogged()
    },
    isMobile() {
      return this.showMobile      
    },   
  },
  methods: {
    handleMobileView() {
      this.showNavMobile = window.innerWidth <= 991;
    },
    showMobileMenu() {
      this.showMenu = !this.showMenu;
      this.$emit("handleMobileMenu", this.showMenu);
    },    
    updClientStatus(status){
      this.isLogged = status
    },
    loginForm(status=true) {
      this.showMenu = !this.showMenu;
      this.$emit('showLogin', status)
    },
    async logout() {
      try {
          // Llamando al API para desloguearse //   
          await Authorization.logout()
          this.isLogged = false
          if (this.$route.fullPath != '/') {
                this.$router.push('/')
          }
          
      } catch(e) {                
          console.log(e)
      }
    },
    isClientLogged() {
      this.isLogged = Authorization.isClientLogged();
      this.user_name = (this.isLogged) ? localStorage.getItem('user') : ''
      return this.isLogged
    }
  },
};
</script>
<style scoped>
ul li {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--blue_1);
  font-family: 'DINPro-Light';
  padding: 2px 10px;
  margin: 5px;
  font-weight: 700;
  cursor: pointer;
}        
ul li:hover {
  color: var(--orange_1);
}
</style>
